import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { useEditableContext } from "../../../../packages/editing/EditableContext"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { EditableText } from "../../../../packages/editing/EditableText"
import { useHover } from "../../../../packages/hooks/useHover"
import { Localized } from "../../../../packages/localization/Localized"
import { Image, ImageToUrl } from "../../../../reactor/Types/File"
import { Uuid } from "../../../../reactor/Types/Primitives/Uuid"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import { Section } from "../../../../packages/editing/Section"

export type Icon = {
    readonly id: Uuid<"Icon">

    /** The section's title.
     *  @title
     *  @default '{ "en": "Title" }'
     */
    title: Localized<string>

    /** The slug that this icon links to */
    link?: string

    icon: Image
}

Section(IconBar)
function IconBar(section: {
    /**
     * @expand
     */
    icons: Icon[]

    /** The section's subtext. */
    subtext: Localized<string>
}) {
    const ec = useEditableContext()

    return (
        <div
            css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
            `}
        >
            <div
                css={css`
                    background-color: #fff;
                    align-self: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    z-index: 10;
                    font-size: 20px;
                    box-shadow: 0px 0px 50px 0px rgba(97, 80, 124, 0.08);
                    overflow: hidden;
                    max-height: 120px;
                    margin-top: -60px;

                    @media (max-width: 479px) {
                        max-height: 92px;
                        margin-top: -46px;
                    }

                    & > * {
                        &:before {
                            content: "";
                            border-left: 1px solid #f2f1f4;
                            margin-top: 8px;
                            margin-bottom: 8px;
                        }

                        &:first-of-type  {
                            &:before {
                                display: none;
                            }
                        }
                    }
                `}
            >
                <EditableArray arr={section.icons} direction="row" itemTypeName="Icon">
                    {(icon, index) => (
                        <EditableObject
                            obj={icon}
                            key={index}
                            typeName="Icon"
                            actions={{
                                deleteThis() {
                                    section.icons.splice(index, 1)
                                    ec.invalidate()
                                },
                            }}
                        >
                            <IconButton icon={icon} />
                        </EditableObject>
                    )}
                </EditableArray>
            </div>
        </div>
    )
}

function IconButton({ icon }: { icon: Icon }) {
    const { hover, hoverProps } = useHover()
    const { editing } = useEditableContext()
    const { venue, locale } = usePageContext()

    const result = (
        <div
            {...hoverProps}
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 120px;
                filter: ${hover ? "brightness(2)" : "brightness(1)"};
                cursor: pointer;
                transition: transform 0.2s;
                transform: ${hover ? "scale(1.1)" : "scale(1)"};
                color: ${colors.darkPurple};
                padding-top: 8;
                padding-bottom: 8;
                justify-content: center;
                height: 120px;
                padding-left: 24px;
                padding-right: 24px;

                @media (max-width: 479px) {
                    padding-left: 14px;
                    padding-right: 14px;
                    height: 92px;
                    font-size: 16px;
                }
            `}
        >
            <div
                css={css`
                    height: 32px;
                    margin-bottom: 16px;
                    @media (max-width: 479px) {
                        height: 24px;
                        margin-bottom: 8px;
                    }
                `}
            >
                {icon.icon && <img src={ImageToUrl(icon.icon)} height="100%" />}
            </div>
            <EditableText
                css={css`
                    text-align: center;
                `}
                obj={icon}
                prop="title"
                isLocalized={true}
            />
        </div>
    )

    if (!editing && icon.link) {
        return <Link to={`/${locale}/${venue ? venue.slug + "/" : ""}${icon.link}`}>{result}</Link>
    } else return result
}
